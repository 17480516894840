import React from "react";

const Loader = ({text}) => {
  return (
      <div className="col-lg-10 offset-lg-1">
          <div className={"Loader"}>
          </div>
          {text && <div>{text}</div>}
      </div>
  )
};

export default Loader;