import React from "react";
import {Field} from "react-final-form";
import PropTypes from 'prop-types';
import Switcher from "./Switcher";
import Selector from "./Selector";

const InputField = props => {


    const getComponent = ({input, label, type, settings, meta: {touched, error}}) => {
        let comp = null;
        switch (props.type) {
            case "input": {
                comp = (
                    <input {...input} type={type} placeholder={props.placeholder} className={"form-control"}/>
                );
                break;
            }
            case "text": {
                comp = (
                    <textarea {...input} placeholder={props.placeholder} className={"form-control"}/>
                );
                break;
            }

            case "switcher": {
                comp = (
                    <Switcher {...input}/>
                )
                break;
            }

            case "select": {
                comp = (
                    <Selector {...input} options={props.options} isMulti={props.isMulti} />
                );
                break;
            }

            default: {
                comp = (
                    <input {...input} type={type} placeholder={props.placeholder} className={"form-control"}/>
                );
            }
        }


        return (
            <div style={props.style}>
                {label &&
                <label className={"enigoo-label"}>{label}</label>}
                {comp}
                {touched && error && <span className={"text-danger font-weight-bold small"}>Toto pole je povinné</span>}
            </div>
        )
    };


    const parse = (value) => {
        if (props.type === "datetime") {
            if (value) {
                return value.format()
            }
        } else if (props.type === "select") {
            if (value) {
                if (props.isMulti) {
                    let array = [];
                    value.map((item) => {
                        return array.push(item.value)
                    });
                    return array;
                } else {
                    return value.value
                }

            }
        }

        return value;
    };


    return (
        <Field
            {...props}
            name={props.name}
            initialValue={props.initialValue}
            label={props.label}
            parse={value => parse(value)}
            validate={value => {
                if (props.validate === true) {
                    return !value;
                } else if (typeof props.validate === "function") {
                    return props.validate(value)
                }

                return undefined;
            }}
        >
            {props => (
                getComponent(props)
            )}
        </Field>
    )
}


InputField.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.oneOf([
        "input",
        "datetime",
        "switcher",
        "select",
        "text"
    ]),
    label: PropTypes.string,
    initialValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object,
        PropTypes.bool
    ]),
    options: PropTypes.array,
    isMulti: PropTypes.bool,
    placeholder: PropTypes.string
}


InputField.defaultProps = {
    isMulti: false
}

export default InputField;
