import React, {Fragment, useState} from "react";
import arrayMutators from "final-form-arrays";
import {Field, Form, FormSpy} from "react-final-form";
import {FieldArray} from "react-final-form-arrays";
import moment, {max} from 'moment';
import PropTypes from "prop-types";
import FieldCollectionPeople from "./FieldCollectionPeople";
import FieldCollectionServices from "./FieldCollectionServices";
import DatePicker from "react-date-picker";
import cogoToast from "cogo-toast";

const required = value => (value ? undefined : 'Pole je povinné.');

const BookingForm = ({nationality, services, additionalServices, submit, initialVal, hash}) => {
    const [dateFrom, setDateFrom] = useState(initialVal ? initialVal.dateFrom : null);
    const [dateTo, setDateTo] = useState(initialVal ? initialVal.dateTo : null);
    return (
        <Fragment>
            <Form
                onSubmit={submit}
                mutators={{
                    ...arrayMutators
                }}
                initialValues={{
                    ...initialVal,
                    hash: hash,
                    note: null,
                    countAdult: 1,
                    countKids: 0,
                }}
                render={({
                             handleSubmit,
                             form: {
                                 mutators: {push, pop}
                             }, // injected from final-form-arrays above
                             pristine,
                             form,
                             submitting,
                             values
                         }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <div className="form-row">
                                <div className="form-group col-lg-12">
                                    <h3>Základní informace</h3>
                                </div>
                                <div className="col-lg-6">
                                    <div>
                                        <label htmlFor="dateFrom"><span className={"require"}>*</span> Datum
                                            příjezdu</label>
                                        <Field
                                            name="dateFrom"
                                            render={props => {
                                                props.input.onChange(dateFrom);
                                                return <DatePicker className={"form-control"}
                                                                   onChange={dt => setDateFrom(dt)}
                                                                   value={dateFrom}/>;
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div>
                                        <label htmlFor="dateTo"><span className={"require"}>*</span> Datum
                                            odjezdu</label>
                                        <Field
                                            name="dateTo"
                                            render={props => {
                                                props.input.onChange(dateTo);
                                                return <DatePicker className={"form-control"}
                                                                   onChange={dt => setDateTo(dt)}
                                                                   value={dateTo}/>;
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="form-group col-lg-6">
                                    <Field name={"firstName"} validate={required}>
                                        {({input, meta}) => (
                                            <div>
                                                <label><span
                                                    className={"require"}>*</span> Jméno</label>
                                                <input {...input} type="text"
                                                       className={"form-control " + (meta.error && meta.touched && "is-invalid")}/>
                                                {meta.error && meta.touched &&
                                                    <span className="invalid-feedback">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                </div>
                                <div className="form-group col-lg-6">
                                    <Field name={"lastName"} validate={required}>
                                        {({input, meta}) => (
                                            <div>
                                                <label><span
                                                    className={"require"}>*</span> Příjmení</label>
                                                <input {...input} type="text"
                                                       className={"form-control " + (meta.error && meta.touched && "is-invalid")}/>
                                                {meta.error && meta.touched &&
                                                    <span className="invalid-feedback">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                </div>
                                <div className="form-group col-lg-6">
                                    <Field name={"phone"} validate={required}>
                                        {({input, meta}) => (
                                            <div>
                                                <label><span className={"require"}>*</span> Telefonní
                                                    číslo</label>
                                                <input {...input} type="text"
                                                       className={"form-control " + (meta.error && meta.touched && "is-invalid")}/>
                                                {meta.error && meta.touched &&
                                                    <span className="invalid-feedback">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                </div>
                                <div className="form-group col-lg-6">
                                    <Field name={"mail"} validate={required}>
                                        {({input, meta}) => (
                                            <div>
                                                <label><span
                                                    className={"require"}>*</span> E-mail</label>
                                                <input {...input} type="text"
                                                       className={"form-control " + (meta.error && meta.touched && "is-invalid")}/>
                                                <small className="form-text text-muted">Sem zašleme potvrzení a
                                                    rekapitulaci
                                                    rezervace.</small>
                                                {meta.error && meta.touched &&
                                                    <span className="invalid-feedback">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                </div>
                                <div className="form-group col-lg-6">
                                    <Field name={"street"} validate={required}>
                                        {({input, meta}) => (
                                            <div>
                                                <label><span className={"require"}>*</span> Ulice</label>
                                                <input {...input} type="text"
                                                       className={"form-control " + (meta.error && meta.touched && "is-invalid")}/>
                                                {meta.error && meta.touched &&
                                                    <span className="invalid-feedback">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                </div>
                                <div className="form-group col-lg-6">
                                    <Field name={"city"} validate={required}>
                                        {({input, meta}) => (
                                            <div>
                                                <label><span
                                                    className={"require"}>*</span> Město</label>
                                                <input {...input} type="text"
                                                       className={"form-control " + (meta.error && meta.touched && "is-invalid")}/>
                                                {meta.error && meta.touched &&
                                                    <span className="invalid-feedback">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                </div>
                                <div className="form-group col-lg-6">
                                    <Field name={"zip"} validate={required}>
                                        {({input, meta}) => (
                                            <div>
                                                <label><span
                                                    className={"require"}>*</span> PSČ</label>
                                                <input {...input} type="text"
                                                       className={"form-control " + (meta.error && meta.touched && "is-invalid")}/>
                                                {meta.error && meta.touched &&
                                                    <span className="invalid-feedback">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                </div>
                                <div className="form-group col-lg-6"></div>
                                <div className="form-group col-lg-6">
                                    <Field name={"countAdult"} validate={required}>
                                        {({input, meta}) => (
                                            <div>
                                                <label><span className={"require"}>*</span> Počet
                                                    dospělých</label>
                                                <input {...input} type="number"
                                                       className={"form-control " + (meta.error && meta.touched && "is-invalid")}
                                                       min={1} max={8} defaultValue={1}
                                                />
                                                {meta.error && meta.touched &&
                                                    <span className="invalid-feedback">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                </div>
                                <div className="form-group col-lg-6">
                                    <Field name={"countKids"} validate={required}>
                                        {({input, meta}) => (
                                            <div>
                                                <label><span className={"require"}>*</span> Počet
                                                    dětí</label>
                                                <input {...input} type="number"
                                                       className={"form-control " + (meta.error && meta.touched && "is-invalid")}
                                                       min={0} max={8} defaultValue={0}
                                                />
                                                {meta.error && meta.touched &&
                                                    <span className="invalid-feedback">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                </div>
                                <div className="form-group col-lg-12">
                                    <label>Poznámka</label>
                                    <Field name="note" component="textarea"
                                           placeholder="Máte dotaz nebo nějaký požadavek? Sdělte nám ho.."
                                           className={"form-control"}/>
                                </div>
                            </div>
                            <hr/>
                            {additionalServices.length > 0 &&
                                <Fragment>
                                    <h3>Přistýlka</h3>
                                    {/*<p className="text-muted">
                                </p>*/}
                                    <FieldArray name="services">
                                        {({fields}) =>
                                            fields.map((name, index) => (
                                                <FieldCollectionServices
                                                    key={index}
                                                    index={index}
                                                    fields={fields}
                                                    name={name}
                                                    values={values}
                                                    services={services}
                                                    additionalServices={additionalServices}
                                                />
                                            ))
                                        }
                                    </FieldArray>
                                    <div>
                                        <button
                                            type="button"
                                            className={"btn btn-info"}
                                            onClick={() => push('services', undefined)
                                            }>Přidat službu
                                        </button>
                                    </div>
                                    <hr/>
                                </Fragment>
                            }
                            <h3>Hosté</h3>
                            <p className="text-muted">
                                Vyplňte prosím údaje všech dopsělých osob, které budou
                                ubytovány s Vámi, včetně Vás.<br/>Tento proces urychlí ubytování po příjezdu.
                            </p>
                            <FieldArray name="people">
                                {({fields}) =>
                                    fields.map((name, index) => (
                                        <FieldCollectionPeople
                                            key={index}
                                            fields={fields}
                                            name={name}
                                            index={index}
                                            nationality={nationality}
                                            required={required}
                                        />
                                    ))
                                }
                            </FieldArray>
                            <div className={"row"}>
                                <div className="col-lg-6">
                                    <button
                                        type="button"
                                        className={"btn btn-info"}
                                        onClick={() => push('people', undefined)
                                        }>Přidat hosta
                                    </button>
                                </div>
                            </div>
                            <hr/>
                            <Field name={`gdpr`} validate={required}>
                                {({input, meta}) => (
                                    <div className="form-check my-3">
                                        <input {...input} type="checkbox"
                                               className={"form-check-input " + (meta.error && meta.touched && "is-invalid")}
                                               id={"gdpr-checbox"}
                                        />
                                        <label htmlFor={"gdpr-checbox"} className="form-check-label">Souhlasím s <a
                                            href="http://docs.enigoo.cloud/docs/zoopark/docs/VOP_ZooChomutov-Ubytovani.pdf"
                                            target={"_blank"}
                                            rel={"noreferrer"}>VOP</a></label>
                                        {meta.error && meta.touched &&
                                            <span className="invalid-feedback">{meta.error}</span>}
                                    </div>
                                )}
                            </Field>
                            <FormSpy
                                subscription={{values: true}}
                                render={({values}) => {
                                    const maxPersons = 8;
                                    const sumPerson = parseInt(values.countAdult) + parseInt(values.countKids)
                                    console.log(sumPerson)
                                    if (sumPerson > maxPersons) {
                                        cogoToast.warn(`Byl překročen maximální počet osob. Maximální počet je ${maxPersons}`)
                                    }

                                    let price = 0;
                                    let dayCount = 0;

                                    if (values.dateFrom && values.dateTo) {
                                        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
                                        const firstDate = moment(values.dateFrom)._d;
                                        const secondDate = moment(values.dateTo)._d;
                                        dayCount = Math.round(Math.abs((firstDate - secondDate) / oneDay));
                                        price += dayCount * 6000;
                                    }

                                    if (values.services) {
                                        values.services.map(item => {
                                            additionalServices.map(serv => {
                                                if (item) {
                                                    if (parseInt(item.serviceSubCategory) === serv.value) {
                                                        price += serv.price * dayCount
                                                    }
                                                }
                                            })
                                        })
                                    }
                                    values.price = price;
                                    return <div className={"h4"}>Cena: {price} Kč vč. DPH</div>;
                                }}
                            />
                            <div className="buttons mt-4">
                                <button type="submit" className={"btn btn-success w-100 rounded-0 py-2"}
                                        disabled={submitting || pristine}>
                                    Rezervovat a zaplatit
                                </button>
                            </div>
                        </form>
                    )
                }}/>
        </Fragment>

    )
};

BookingForm.propTypes = {
    nationality: PropTypes.array.isRequired,
    services: PropTypes.array.isRequired,
    additionalServices: PropTypes.array.isRequired,
    submit: PropTypes.func.isRequired,
    initialVal: PropTypes.object,
    hash: PropTypes.string
};

export default BookingForm;
