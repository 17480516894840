import React from "react";
import Select from "react-select";

const Selector = props => {

    const setValue = (value) => {
        if (props.isMulti) {
            let array = [];
            // eslint-disable-next-line array-callback-return
            props.options.map((item, key) => {
                if (value) {
                    // eslint-disable-next-line array-callback-return
                    value.map((_value) => {
                        if (_value === item.value) {
                            return array.push(item);
                        }
                    })
                }
            });

            return array;

        } else {
            // eslint-disable-next-line array-callback-return
            return props.options.map((item, key) => {
                if (item.value === value) {
                    return item;
                }
            })
        }
    };

    return (

        <Select
            onChange={(value) => props.onChange(value)}
            options={props.options}
            isMulti={props.isMulti}
            value={setValue(props.value)}
        />
    )
};


export default Selector;