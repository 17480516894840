import React, {Component} from "react";
import {Link} from "react-router-dom";

class NotFound extends Component {

    render() {
        return (
            <div className={"NotFound"}>
                <div className={"container"}>
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <h2>Stránka nebyla nalezena.</h2>
                            <div className="back-to-home">
                                <Link className={"primary-btn"} to={"/"}>Zpět na hlavní stránku</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default NotFound;