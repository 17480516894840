import React, {Fragment, useState} from "react";
import {Field} from "react-final-form";
import InputField from "./InputField";
import moment from "moment";
import DatePicker from "react-date-picker";

const Condition = ({when, is, children}) => (
    <Field name={when} subscription={{value: true}}>
        {({input: {value}}) => (value === is ? children : null)}
    </Field>
);

const FieldCollectionPeople = ({fields, name, index, nationality, required}) => {
    const [bDay, setBDay] = useState(null);
    return (
        <Fragment>
            <label><strong>Host #{index + 1}</strong></label>
            <div className="form-row">
                <div className="form-group col-lg-4">
                    <Field name={`${name}.firstName`}
                           validate={required}>
                        {({input, meta}) => (
                            <Fragment>
                                <label><span
                                    className={"require"}>*</span> Jméno</label>
                                <input {...input} type="text"
                                       className={"form-control " + (meta.error && meta.touched && "is-invalid")}/>
                                {meta.error && meta.touched &&
                                <span className="invalid-feedback">{meta.error}</span>}
                            </Fragment>
                        )}
                    </Field>
                </div>
                <div className="form-group col-lg-4">
                    <Field name={`${name}.lastName`}
                           validate={required}>
                        {({input, meta}) => (
                            <Fragment>
                                <label><span
                                    className={"require"}>*</span> Příjmení</label>
                                <input {...input} type="text"
                                       className={"form-control " + (meta.error && meta.touched && "is-invalid")}/>
                                {meta.error && meta.touched &&
                                <span className="invalid-feedback">{meta.error}</span>}
                            </Fragment>
                        )}
                    </Field>
                </div>
                <div className="form-group col-lg-4">
                    <label htmlFor={`${name}.bDay`}><span
                        className={"require"}>*</span> Datum
                        narození</label>
                    <Field
                        name={`${name}.bDay`}
                        render={props => {
                            props.input.onChange(bDay);
                            return <DatePicker className={"form-control"}
                                               onChange={dt => setBDay(dt)}
                                               value={bDay}/>;
                        }}
                    />
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-lg-5">
                    <Field name={`${name}.city`} validate={required}>
                        {({input, meta}) => (
                            <Fragment>
                                <label><span
                                    className={"require"}>*</span> Město</label>
                                <input {...input} type="text"
                                       className={"form-control " + (meta.error && meta.touched && "is-invalid")}/>
                                {meta.error && meta.touched &&
                                <span
                                    className="invalid-feedback">{meta.error}</span>}
                            </Fragment>
                        )}
                    </Field>
                </div>
                <div className="form-group col-lg-4">
                    <Field name={`${name}.street`} validate={required}>
                        {({input, meta}) => (
                            <Fragment>
                                <label><span
                                    className={"require"}>*</span> Ulice
                                    a
                                    čp.</label>
                                <input {...input} type="text"
                                       className={"form-control " + (meta.error && meta.touched && "is-invalid")}/>
                                {meta.error && meta.touched &&
                                <span
                                    className="invalid-feedback">{meta.error}</span>}
                            </Fragment>
                        )}
                    </Field>
                </div>
                <div className="form-group col-lg-3">
                    <Field name={`${name}.zip`} validate={required}>
                        {({input, meta}) => (
                            <Fragment>
                                <label><span
                                    className={"require"}>*</span> PSČ</label>
                                <input {...input} type="text"
                                       className={"form-control " + (meta.error && meta.touched && "is-invalid")}/>
                                {meta.error && meta.touched &&
                                <span
                                    className="invalid-feedback">{meta.error}</span>}
                            </Fragment>
                        )}
                    </Field>
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-lg-5">
                    <InputField
                        name={`${name}.cardType`}
                        type={"select"}
                        label={"Typ dokladu"}
                        options={[
                            {value: '1', label: 'Občanský průkaz'},
                            {value: '2', label: 'Řidičský průkaz'},
                            {value: '3', label: 'Cestovní pas'}
                        ]}
                        validate={false}
                        required={false}
                    />
                </div>
                <div className="form-group col-lg-4">
                    <Field name={`${name}.cardNumber`}
                           validate={false}
                           required={false}>
                        {({input, meta}) => (
                            <Fragment>
                                <label><span
                                    className={"require"}>*</span> Číslo
                                    dokladu</label>
                                <input {...input} type="text"
                                       className={"form-control " + (meta.error && meta.touched && "is-invalid")}/>
                                {meta.error && meta.touched &&
                                <span
                                    className="invalid-feedback">{meta.error}</span>}
                            </Fragment>
                        )}
                    </Field>
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-lg-5">
                    <Field name={`${name}.stranger`}>
                        {({input, meta}) => (
                            <div className="form-check my-3">
                                <input {...input} type="checkbox"
                                       className={"form-check-input"}
                                       id={`${name}.stranger-checkbox`}
                                />
                                <label
                                    htmlFor={`${name}.stranger-checkbox`}
                                    className="form-check-label">Jsem
                                    cizinec</label>
                            </div>
                        )}
                    </Field>
                </div>
                <div className="col-lg-7 text-right">
                    <div className="form-group">
                        <button className="btn btn-danger"
                                onClick={() => fields.remove(index)}>
                            Odebrat hosta #{index + 1}
                        </button>
                    </div>
                </div>
            </div>
            <div className="form-row">
                <div className="col-lg-5 form-group">
                    <Condition when={`${name}.stranger`} is={true}>
                        <InputField
                            name={`${name}.nationality`}
                            type={"select"}
                            label={"Vyber národnost"}
                            options={nationality.map(item => {
                                return item
                            })}
                        />
                    </Condition>
                </div>
            </div>
        </Fragment>
    )
};

export default FieldCollectionPeople;
