import React from "react";
import Switch from "rc-switch"


const Switcher = props => {

    return (
        <Switch
            className={"rc-enigoo-switcher"}
            onChange={(value) => props.onChange(value)}
            checked={props.value ? props.value : false}
            onClick={(value) => props.onChange(value)}
        />
    )
}

export default Switcher;