import React from "react";

const InfoPage = () => {
    return (
        <section id="accomodation">
            <img className="banner-img" src="../zoopark_roubenka2.jpg" draggable="false"
                 alt="banner"/>
            <h2>Zážitkové bydlení v roubence</h2>
            <p>
                Zažijte bydlení v unikátním prostoru historické roubenky. Roubenka se nachází v příjemném prostředí
                areálu Stará Ves a díky svému uspořádání je ideální pro ubytování větších rodin, skupiny přátel nebo
                cyklistů. Součástí roubenky jsou 2 ložnice se společným sociálním zařízením a velká společenská místnost
                s kuchyňkou.
            </p>
            <p>
                Pro cyklisty je připraven uzamykatelný prostor na uskladnění kol s možností dobití elektrokol.
            </p>
            <ul>
                <li>kapacita 8 osob (1x dvoulůžko + 1x jednolůžko, 1x dvoulůžko + 3x jednolůžko)</li>
                <li>návštěva zooparku (možnost i ve večerních hodinách v ceně pobytu)</li>
            </ul>
            <p>
                <strong>Stravování</strong>
                <br/>
                stravování vlastní – k dispozici je plně vybavená kuchyňka (lednice, mikrovlnná trouba)
            </p>

            <p>
                Check in: 15,00 - 17,00 (později dle domluvy)<br/>
                Check out: 10,00
            </p>
            <p>e-mail: <a href="mailto:skanzen@zoopark.cz">skanzen&#064;zoopark.cz</a></p>
            <p>Ubytování v roubence je možné od 1. 5. do 30. 9.</p>
            <p>
                Storno podmínky se vztahují na zrušení rezervace ze strany klienta.<br/>
                0-5 dnů před nástupem = 100 % z ceny ubytování,<br/>
                6-14 dnů před nástupem = 50 % z ceny ubytování,<br/>
                15-30 dnů před nástupem = 35 % z ceny ubytování.<br/><br/>
                Uvedené ceny jsou včetně DPH
            </p>
        </section>
    )
};

export default InfoPage;
