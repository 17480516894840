import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import {createBrowserHistory} from 'history';
import {Router, Route, Switch} from "react-router-dom";

import App from './js/App';
import Homepage from "./js/Page/Homepage";
import Blocation from "./js/Page/Blocation";
import NotFound from "./js/Page/NotFound";
import PaymentDone from "./js/Page/PaymentDone";

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

const history = createBrowserHistory();

ReactDOM.render(
    <React.StrictMode>
        <Router history={history}>
            <main className={"main-content"} role={"main"}>
                <App>
                    <Switch>
                        <Route path={'/'} exact={true} component={Homepage}/>
                        <Route path={'/blokace/:hash'} component={Blocation}/>
                        <Route path={'/gopay/done'} component={PaymentDone}/>
                        <Route component={NotFound}/>
                    </Switch>
                </App>
            </main>
                <footer className={"text-center my-4"}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1">
                                2023 &copy; Powered by <a href="https://enigoo.cz/" target={"_blank"} rel={"noreferrer"}>ENIGOO</a> 🚀
                            </div>
                        </div>
                    </div>
                </footer>
        </Router>
    </React.StrictMode>,
    document.getElementById('app')
);

serviceWorker.unregister();
