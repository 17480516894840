import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './PaymentStatusMessage.css';

const PaymentStatusMessage = ({status, oid}) => {

    const renderStatusIcon = () => {
        let result;
        if (status === "CREATED") {
            result = <FontAwesomeIcon icon={"times-circle"} size={"7x"} className={"text-secondary"}/>;
        } else if (status === "PAYMENT_METHOD_CHOSEN") {
            result = <FontAwesomeIcon icon={"credit-card"} size={"7x"} className={"text-secondary"}/>;
        } else if (status === "PAID") {
            result = <FontAwesomeIcon icon={"check-circle"} size={"7x"} className={"text-success"}/>;
        } else if (status === "AUTHORIZED") {
            result = <FontAwesomeIcon icon={"cog"} spin size={"7x"} className={"text-secondary"}/>;
        } else if (status === "CANCELED") {
            result = <FontAwesomeIcon icon={"times-circle"} size={"7x"} className={"text-danger"}/>;
        } else if (status === "TIMEOUTED") {
            result = <FontAwesomeIcon icon={"times-circle"} size={"7x"} className={"text-danger"}/>;
        } else {
            result = <FontAwesomeIcon icon={"times-circle"} size={"7x"} className={"text-danger"}/>;
        }

        return result;
    };

    const renderStatusMessage = () => {
        let result;
        if (status === "CREATED") {
            result = "Platba založena";
        } else if (status === "PAYMENT_METHOD_CHOSEN") {
            result = "Platební metoda vybrána";
        } else if (status === "PAID") {
            result = "Úspěšně zaplaceno";
        } else if (status === "AUTHORIZED") {
            result = "Platba předautorizována";
        } else if (status === "CANCELED") {
            result = "Platba zrušena";
        } else if (status === "TIMEOUTED") {
            result = "Vypršelá platnost platby";
        } else {
            result = "Nastala chyba";
        }

        return result;
    };

    return (
        <div className={"PaymentStatusMessage"}>
            {renderStatusIcon()}
            <h4 className={"pt-3"}>{renderStatusMessage()}</h4>
            <div>Číslo rezervace: <strong>{oid}</strong></div>
            <div className={"mt-4 small text-muted"}>V případě nouze nás kontaktujte na <a href="mailto:skanzen@zoopark.cz">skanzen&#064;zoopark.cz</a></div>
        </div>
    )

};

export default PaymentStatusMessage;