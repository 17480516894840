import React, {Fragment} from "react";
import {Field} from "react-final-form";

const FieldCollectionServices = ({fields, name, index, values, services, additionalServices}) => {
    return (
        <Fragment>
            <label><strong>Přistýlka #{index + 1}</strong></label>
            <div className="row align-items-end">
                <div className="col-lg-5">
                    <div className="form-group">
                        <label htmlFor={`${name}.serviceCategory`}>Kategorie služby</label>
                        <Field
                            name={`${name}.serviceCategory`}
                            component="select"
                            className={"form-control"}>
                            <option>--</option>
                            {services.map((item, index) => {
                                return <option key={index}
                                               value={item.value}>{item.label}</option>
                            })}
                        </Field>
                    </div>
                </div>
                <div className="col-lg-5">
                    <div className="form-group">
                        <label
                            htmlFor={`${name}.serviceSubCategory`}>Služba</label>
                        <Field
                            name={`${name}.serviceSubCategory`}
                            component="select"
                            className={"form-control"}>
                            <option>--</option>
                            {values.services[index] &&
                            additionalServices
                                .filter(item => item.serviceId === parseInt(values.services[index].serviceCategory))
                                .map((_item, index) => {
                                    return (
                                        <option key={index} value={_item.value}>
                                            {_item.label} ({_item.price} Kč)
                                        </option>
                                    )
                                })}
                        </Field>
                    </div>
                </div>
                <div className="col-lg-2">
                    <div className="form-group">
                        <button className="btn btn-danger"
                                onClick={() => fields.remove(index)}>
                            Odebrat
                        </button>
                    </div>
                </div>
            </div>
        </Fragment>
    )
};

export default FieldCollectionServices;
