import React from "react";
import './LegendItem.css';

const LegendItem = ({color, name}) => {
  return (
      <div className={"LegendItem"}>
          <div className="LegendItem_color" style={{background: color}}></div>
          <div className="LegendItem_name">{name}</div>
      </div>
  )
};

export default LegendItem;