import React, {Component, Fragment} from 'react';
import {ApiAxios} from "../../axiosConfig";
import BookingForm from "../Component/BookingForm";
import Loader from "../Component/Loader";
import cogoToast from 'cogo-toast';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import LegendWrapper from "../Component/LegendItem/LegendWrapper";
import moment from "moment";

class Blocation extends Component {

    constructor() {
        super();
        this.state = {
            events: null,
            nationality: null,
            services: null,
            data: null,
            serviceAdditional: null,
            isLoaded: false,
        };
    }

    componentDidMount() {
        this._fetchChannel();
        ApiAxios.post('v1/blocation', {hash: this.props.match.params.hash})
            .then(res => {
                this.setState({
                    data: res.data.data
                })
            })
            .catch(err => {
                console.error(err);
            })
    }

    _fetchChannel = () => {
        ApiAxios.get(`/v1/init`)
            .then(res => {
                let arrNationality = [];
                let arrEvents = [];
                let arrServices = [];
                let arrSerAdditional = [];
                res.data.data.dates.map(item => {
                    return arrEvents.push(item);
                });
                res.data.data.services.map(item => {
                    return arrServices.push({value: item.id, label: item.name});
                });
                res.data.data.additional.map(item => {
                    return arrSerAdditional.push({
                        value: item.id,
                        label: item.name,
                        price: item.price,
                        serviceId: item.serviceId
                    });
                });
                res.data.data.nationality.map(item => {
                    return arrNationality.push({value: item.id, label: item.name});
                });
                this.setState({
                    nationality: arrNationality,
                    events: arrEvents,
                    services: arrServices,
                    serviceAdditional: arrSerAdditional,
                    isLoaded: true
                });
            }).catch(err => {
            console.error(err);
        });
    };

    onSumbit = (values) => {

        let dFrom = moment(values.dateFrom).format('YYYY-MM-DD');
        let dTo = moment(values.dateTo).format('YYYY-MM-DD');

        ApiAxios.post(`v1/verify-dates`, {
            dateFrom: dFrom,
            dateTo: dTo,
            hash: null
        })
            .then(res => {
                if (res.data.result) {
                    if (dFrom.length > 1 && dTo.length > 1 && dFrom < dTo) {
                        if (/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(values.mail)) {
                            if (typeof values.people !== 'undefined' && values.people.length > 0) {
                                let hosts = [];
                                values.people.map((item, index) => {
                                    hosts.push({
                                        cardNumber: item.cardNumber,
                                        cardType: item.cardType,
                                        city: item.city,
                                        zip: item.zip,
                                        firstName: item.firstName,
                                        lastName: item.lastName,
                                        street: item.street,
                                        bday: moment(item.bDay).format('YYYY-MM-DD'),
                                    })
                                });

                                ApiAxios.post('v1/update-reservation', {
                                    firstName: values.firstName,
                                    lastName: values.lastName,
                                    phone: values.phone,
                                    mail: values.mail,
                                    countAdult: values.countAdult,
                                    countKids: values.countKids,
                                    note: values.note ? values.note : null,
                                    dateFrom: dFrom,
                                    dateTo: dTo,
                                    street: values.street,
                                    city: values.city,
                                    zip: values.zip,
                                    status: values.status,
                                    price: values.price,
                                    gdpr: values.gdpr,
                                    people: hosts, // array
                                    services: values.services // array
                                }).then(res => {
                                    if (res.data.result === false) {
                                        cogoToast.error(res.data.error)
                                    } else {
                                        window.location.href = res.data.data;
                                    }
                                }).catch(err => {
                                    console.error(`Error: ${err}`);
                                })
                            } else {
                                cogoToast.error('Vyplňte hosty.');
                            }
                        } else {
                            cogoToast.error('E-mail není validní.');
                        }
                    } else {
                        cogoToast.error('Vyplňte řádně datum.');
                    }
                } else {
                    cogoToast.error('Termín není k dispozici.');
                }
            })
            .catch(err => {
                console.error(err);
            });

    };

    render() {
        return (
            <div className={"container my-4"}>
                <div className="row">
                    {this.state.isLoaded ?
                        <Fragment>
                            <div className="col-lg-10 offset-lg-1">
                                <LegendWrapper/>
                                <FullCalendar
                                    plugins={[dayGridPlugin, interactionPlugin]}
                                    firstDay={1}
                                    initialView="dayGridMonth"
                                    height={550}
                                    locale={'cs'}
                                    events={this.state.events}
                                />
                            </div>
                            <div className="col-lg-10 offset-lg-1 mt-5">
                                <BookingForm
                                    initialVal={this.state.data}
                                    nationality={this.state.nationality}
                                    services={this.state.services}
                                    additionalServices={this.state.serviceAdditional}
                                    submit={(values) => this.onSumbit(values)}
                                    hash={this.props.match.params.hash}
                                />
                            </div>
                        </Fragment>
                        :
                        <Loader/>
                    }
                </div>
            </div>
        )
    }
}

export default Blocation;
