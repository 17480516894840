import React, {Component} from "react";
import Loader from "../Component/Loader";
import queryString from 'querystring';
import {ApiAxios} from "../../axiosConfig";
import PaymentStatusMessage from "../Component/PaymentStatusMessage/PaymentStatusMessage";

class Response extends Component {

    constructor() {
        super();
        this.state = {
            responseData: null,
            responseResult: null,
            loading: true
        }
    }

    componentDidMount() {
        let data = queryString.parse(this.props.location.search.substr(1));
        ApiAxios.post('/v1/gopay/verify-payment', data)
            .then(res => {
                this.setState({
                    responseData: res.data.data,
                    responseResult: res.data.error,
                    loading: false
                });
            })
            .catch(err => {
                console.error(`${err}`);
            });
    }

    _renderResult = (errorCode, data) => {
        if (errorCode === 403) {
            return <div>GoPay neodpovídá.</div>
        } else if (errorCode === 404) {
            return <div>Objednávka nenalezena.</div>
        } else {
            return <PaymentStatusMessage
                status={data.state}
                oid={data.orderId}
            />
        }
    };

    render() {
        return (
            <div className={"container py-4"}>
                <div className="co-lg-12 text-center">
                    {!this.state.loading ? this._renderResult(this.state.responseResult, this.state.responseData) :
                        <Loader text={"Čekám na odpověď GoPaye."}/>}
                </div>
            </div>
        )
    }
}

export default Response;