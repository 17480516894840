import React from "react";
import LegendItem from "./LegendItem";

const LegendWrapper = () => {
    return (
        <div className={"d-block"}>
            <LegendItem name={"Dnešní den"} color={'#fffadf'}/>
            <LegendItem name={"Obsazeno"} color={'#fad2d2'}/>
            <LegendItem name={"Volno"} color={'#ffffff'}/>
        </div>
    )
};

export default LegendWrapper;